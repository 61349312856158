/**
 * Live Channel Class.
 *
 * @link https://docs.jwplayer.com/platform/reference/automatically-broadcast-a-live-channels-event-stream#full-code-example
 */
class LiveChannel {
  /**
   * How often we should be checking for an update to the live status.
   * 10 seconds is generally a good frequency, it is not useful to check more often.
   */
  UPDATE_FREQUENCY = 10 * 1e3;

  /**
   * The code of the error which the player may raise if the livestream goes offline or finishes.
   */
  LIVESTREAM_COMPLETE_ERROR = 230001;

  /**
   * The code of the error emitted by JW Player's HLS implementation if buffering stalls.
   * This may happen when a livestream (suddenly) finishes and no more segments are available to buffer.
   * In this case we'll switch back to VOD playback.
   */
  HLS_BUFFER_STALL_WARNING = 334001;

  /**
   * The maximum number of times we'll try before giving up configuring a player.
   * @type {number}
   */
  MAX_RETRIES = 3;

  constructor(videoId, channelId) {
    if (!channelId.match(/[a-zA-Z0-9]{8}/)) {
      console.error(
        "The provided channel id is not a valid Live Channel channel ID."
      );
      return;
    }

    this.channelId = channelId; // The Channel ID. This is shown on the Channel Detail page in the JW Dashboard.
    this.videoId = videoId; // Play a looping VOD asset if the channel is not active.
    this.playlist = `https://cdn.jwplayer.com/v2/media/${videoId}`; // Playlist of the VOD playerinstance.
    this.eventId = null; // The identifier of the current/last played event.
    this.playerInstance = null; // The player on the page which we'll use for playback
    this.timer = null; // An id used by the setInterval()/clearInterval() functions in order to manage the update loop.

    // Listen to the event which is emitted when the player is ready.
    $(window).on(`video_loaded_${this.videoId}`, (e, playerIntance) => {
      // Player need to be ready before we can use it for live event.
      this.onPlayerInstanceReady(playerIntance);

      // Start the update loop .
      this.checkChannelStatus();
    });
  }

  handleLivestreamFinished() {
    if (this.timer) {
      // We are already checking for a livestream.
      // In this state there should not be a reason to re-initialize the player -- it should already be in the correct state.
      return;
    }
    console.log("Detected livestream completion. Switching to VOD playback.");

    // Enable looping of media.
    this.playerInstance.setConfig({ repeat: true });
    // Reload the VOD playlist.
    this.playerInstance.load(this.playlist);

    if (this.channelId) {
      // Start checking for a new event.
      this.checkChannelStatus();
    }

    this.playerInstance.play();
  }

  /**
   * Periodically checks whether the specified livestream channel is available, and if it is, configures the player
   * to start playing it.
   */
  checkChannelStatus = () => {
    if (!this.timer) {
      console.log(
        `Waiting for Live Channel ${this.channelId} to become active.`
      );
      // Make sure to execute this method every UPDATE_FREQUENCY milliseconds.
      this.timer = setInterval(this.checkChannelStatus, this.UPDATE_FREQUENCY);
    }
    this.getChannelStatus(this.channelId).then(
      (channelStatus) => {
        if (channelStatus["status"] === "active") {
          console.log(
            `Received ${this.channelId} channel status: %O`,
            channelStatus
          );
          // Determine the id of the active event based on the returned status.
          const eventId = channelStatus["current_event"];

          // Check if we have seen this eventId before.
          if (this.eventId === eventId) {
            // The eventId returned by the API was not a *new* event id.
            // Ignore it and continue polling until we see a new id.
            return;
          }
          this.eventId = eventId;

          // Stop polling the channel status.
          this.timer = clearInterval(this.timer);

          // Attempt to configure the player in order to start livestream playback.
          this.configurePlayer(eventId).catch((error) => {
            console.log(`Failed to start live event stream playback: ${error}`);
          });
        }
      },
      (error) => {
        console.log(
          `Unable to fetch the channel status for ${this.channelId}: ${error}`
        );
        // If we fail to retrieve the channel status, then give up.
        this.timer = clearInterval(this.timer);
      }
    );
  };

  /**
   * (Re-)configures the active playerInstance to play the livestream identified by eventId.
   */
  configurePlayer = async (eventId) => {
    // There may be a slight delay between the livestream becoming available, and its playlist to become available.
    // Therefore, we first attempt to fetch the playlist for the new live event, as soon as we have successfully fetched
    // a playlist, we will load it on the player and start playback of the livestream.
    let playlist;
    let attempts = 0;
    console.log(`Fetching playlist for ${eventId}.`);
    while (!playlist) {
      try {
        playlist = await this.getPlaylist(eventId);
      } catch (e) {
        ++attempts;
        console.error(e);
        if (attempts >= this.MAX_RETRIES) {
          // Manually set up the player if we were not able to retrieve the playlist after 3 retries
          console.log("Configuring Player with m3u8");
          playlist = {
            playlist: [
              {
                mediaid: eventId,
                file: `https://cdn.jwplayer.com/live/events/${eventId}.m3u8`,
              },
            ],
          };
          break;
        }
        // Retry with exponential backoff, i.e. first retry after 5, 10, 20, 40, 80 seconds
        // after which we ultimately give up.
        await this.sleep(2 ** (attempts - 1) * 5 * 1000);
      }
    }

    // Once a playlist is available, use it to configure the player.
    this.playerInstance.setConfig({ repeat: false });
    this.playerInstance.load(playlist.playlist);

    // Start playback
    this.playerInstance.play();
    console.log(`Playing live event stream with id '${eventId}'.`);
  };

  /**
   * Utility function to fetch a JSON document.
   * @param url
   */
  fetchJSON = async (url, init) => {
    return await fetch(url, init).then((response) => {
      if (!response.ok) {
        throw new Error(`Unable to fetch ${url}: ${response.statusText}`);
      }
      return response.json();
    });
  };

  /**
   * Fetches the current status of a Live Channel.
   * Returns a promise that will yield the status for a particular channel.
   *
   * @param channelId The channel to fetch the status for.
   */
  getChannelStatus = (channelId) => {
    return this.fetchJSON(
      `https://cdn.jwplayer.com/live/channels/${channelId}.json`
    );
  };

  /**
   * Fetches a JW Platform feed for a particular media item.
   *
   * @param mediaId The media id to fetch a single item playlist for.
   */
  getPlaylist = (mediaId) => {
    return this.fetchJSON(`https://cdn.jwplayer.com/v2/media/${mediaId}`, {
      cache: "no-cache",
    });
  };

  /**
   * A simple utility method which can be used to wait for some time between retries.
   *
   * @param ms The amount of milliseconds to wait between retries.
   */
  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  /**
   * All video players are initiated in one place. This method is called when the player is ready.
   *
   * @param playerInstance JW Player instance
   */
  onPlayerInstanceReady = (playerInstance) => {
    if (typeof playerInstance === "undefined" || !playerInstance) {
      return;
    }

    this.playerInstance = playerInstance;

    // Register an event listener that triggers when the JW Player has finished playing all
    // elements in its playlist. In this demo, this event is triggered when livestream playback
    // has finished.
    this.playerInstance.on("playlistComplete", this.handleLivestreamFinished);

    // Register an event listener that triggers when the player emits an error.
    this.playerInstance.on("error", (error) => {
      // Check if the error may have been because the livestream stopped updating, in this case
      // we'll switch back to playing the VOD.
      if (this.playerInstance.getPlaylistItem().mediaid !== this.eventId) {
        // Ignore errors during VOD playback.
        return;
      }
      if (error.code === this.LIVESTREAM_COMPLETE_ERROR) {
        this.handleLivestreamFinished();
      }
    });

    // Register an event listener which listens for buffer warnings from the player.
    // We can use the warnings generated by the player to realize a very fast switchover
    // between the livestream and the VOD asset.
    this.playerInstance.on("warning", (warn) => {
      if (this.playerInstance.getPlaylistItem().mediaid !== this.eventId) {
        // Ignore warnings during VOD playback.
        return;
      }
      if (warn.code === this.HLS_BUFFER_STALL_WARNING) {
        // The player failed to buffer more media.
        // This *may* be an indicator that the livestream has finished - in this demo we'll switch back to attempting to play
        // the VOD asset if this is the case.
        this.handleLivestreamFinished();
      }
    });
  };
}

export default LiveChannel;
