/**
 * Events Grid Slider
 */
const EventsGrid = () => {
  const selectors = {
    slider: '.fc-events-grid__swiper:not(".fc-initialized")',
  };

  const init = () => {
    $(selectors.slider).each((index, el) => {
      el.classList.add("fc-initialized");

      new Swiper(el, {
        slidesPerView: "auto",
        spaceBetween: 0,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    });
  };

  $(window).on("ess_article_loaded", init);

  return { init };
};

EventsGrid().init();
