/**
 * Watch Library.
 *
 * Not used in article iscroll so fc-initialized is not required.
 */
const WatchLibrary = () => {
  const state = {
    search: "",
    paged: 1,
    loading: false,
    ajax: null,
  };

  const selectors = {
    $sliders: $('[data-js="event-watch-library-swiper"]'),
    $clearSearchBtn: $(".fc-watch-library__search-clear"),
    $searchInput: $("#watch-library-search"),
    $posts: $(".fc-watch-library__search-result-posts"),
    $sections: $(".fc-watch-library__sections"),
    $container: $(".fc-watch-library__search-result"),
    $loadmore: $(".fc-watch-library__search-result .loadmore"),
    $loading: $(".fc-watch-library__search-result .loading"),
    $count: $(".fc-watch-library__search-result-title"),
  };

  const updateURL = () => {
    const url = new URL(window.location);
    if (state.search) {
      url.searchParams.set("search", state.search);
    } else {
      url.searchParams.delete("search");
    }
    window.history.pushState({}, "", url);
  };

  const updateLayout = () => {
    if (state.search) {
      // Show search results.
      selectors.$sections.addClass("hidden");
      selectors.$container.removeClass("hidden");
    } else {
      // Show sections.
      selectors.$sections.removeClass("hidden");
      selectors.$container.addClass("hidden");
    }
  };

  const parseURL = () => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    state.search = params.get("search");
    load(false, false, false);
  };

  const registerEvents = () => {
    // Clear search string
    selectors.$clearSearchBtn.on("click", (e) => {
      e.preventDefault();
      state.search = "";
      selectors.$searchInput.val("");
      selectors.$clearSearchBtn.addClass("hidden");

      updateURL();
      updateLayout();
      load(true, true, true);
    });

    // Toggle clear button
    selectors.$searchInput.on("input", () => {
      state.search = selectors.$searchInput.val();
      selectors.$clearSearchBtn.toggleClass("hidden", !state.search);

      updateURL();
      updateLayout();
      load(true, true, true);
    });

    // Load more
    selectors.$loadmore.on("click", (e) => {
      e.preventDefault();
      state.paged += 1;
      load(false, false, false);
    });
  };

  load = (abort = false, clear = false, scroll = false) => {
    if (abort) {
      if (state.ajax) {
        state.ajax.abort();
        state.loading = false;
      }
    }

    if (state.loading) {
      return;
    }

    if (clear) {
      selectors.$posts.empty();
      state.paged = 1;
    }

    state.loading = true;
    selectors.$loading.show();
    selectors.$loadmore.hide();
    selectors.$count.html("");

    state.ajax = $.ajax({
      url: events.ajaxUrl,
      method: "POST",
      data: {
        action: "get_watch_library_events",
        search: state.search,
      },
    })
      .done((response) => {
        if (response.success) {
          selectors.$posts.append(response.data.output);
        }
        console.log("ready", selectors.$posts);

        selectors.$count.html(
          `${response.data.total} Results for <b>“${state.search}</b>”`
        );

        if (state.paged >= response.data.total_pages) {
          selectors.$loadmore.remove();
        } else {
          selectors.$loadmore.show();
        }

        if (scroll) {
          $("html, body").animate(
            {
              scrollTop:
                selectors.$searchInput.offset().top -
                (window.innerWidth > 1124 ? 300 : 200),
            },
            300
          );
        }
      })
      .always(() => {
        state.loading = false;
        selectors.$loading.hide();
      });
  };

  const initSlider = () => {
    selectors.$sliders.each((index, el) => {
      new Swiper(el, {
        navigation: {
          nextEl: $(el).parent().find(".swiper-button-next")[0],
          prevEl: $(el).parent().find(".swiper-button-prev")[0],
        },
        slidesPerView: "auto",
        loop: false,
        spaceBetween: 16,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 768px
          768: {
            // slidesPerView: 3.2,
            spaceBetween: 28,
          },
        },
      });
    });
  };

  const init = () => {
    if (selectors.$sliders.length) {
      initSlider();
    }

    if (selectors.$posts.length) {
      parseURL();
      updateLayout();
      registerEvents();
    }
  };

  return { init };
};

WatchLibrary().init();
