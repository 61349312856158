/**
 * CountDown Timer
 */
const CountDownTimer = () => {
  const selectors = {
    countDown: '[data-js="fc-countdown-timer"]:not(".fc-initialized")',
  };

  const init = () => {
    $(selectors.countDown).each((index, countDownEl) => {
      countDownEl.classList.add("fc-initialized");
      const $countDownEl = $(countDownEl);

      const datetime = new Date(countDownEl.dataset.datetime);
      const currentTime = new Date();

      // Get the time offset.
      const offset = datetime.getTime() - currentTime.getTime();

      // Start timebr.
      start(offset, $countDownEl);
    });
  };

  const start = (offset, $el) => {
    // Return if no offset.
    if (offset <= 0) {
      return;
    }

    // Update immediately.
    update(offset, $el);

    const countDownInterval = setInterval(() => {
      // Countdown.
      offset -= 1000;

      // Stop countdown if offset reaches 0.
      if (offset < 0) {
        clearInterval(countDownInterval);
        return;
      }

      // Update.
      update(offset, $el);
    }, 1000);
  };

  const update = (offset, $el) => {
    // Calculate.
    const days = Math.floor(offset / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (offset % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((offset % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((offset % (1000 * 60)) / 1000);

    // Display.
    $el.find(".days").text((days < 10 ? "0" : "") + days);
    $el.find(".hours").text((hours < 10 ? "0" : "") + hours);
    $el.find(".minutes").text((minutes < 10 ? "0" : "") + minutes);
    // $el.find(".seconds").text((seconds < 10 ? "0" : "") + seconds);
  };

  $(window).on("ess_article_loaded", init);

  return { init };
};

CountDownTimer().init();
