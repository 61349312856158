/**
 * Events Carousel
 */
const EventsCarousel = () => {
  const selectors = {
    slider: '[data-js="fc-events-carousel"]:not(".fc-initialized")',
  };

  const init = () => {
    $(selectors.slider).each((index, el) => {
      el.classList.add("fc-initialized");

      let breakpoints = {
        600: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: el.dataset.slidesPerView || 3,
        },
      };

      if (el.dataset.slidesPerView === "4") {
        breakpoints = {
          600: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: el.dataset.slidesPerView || 3,
          },
        };
      }

      new Swiper(el, {
        navigation: {
          nextEl: $(el).parent().find(".swiper-button-next")[0],
          prevEl: $(el).parent().find(".swiper-button-prev")[0],
        },
        slidesPerView: 1,
        spaceBetween: 24,
        loop: false,
        breakpoints,
      });
    });
  };

  $(window).on("ess_article_loaded", init);

  return { init };
};

EventsCarousel().init();
