/**
 * Experience Feed Carousel
 */
const ExperienceFeed = () => {
  const selectors = {
    slider: '[data-js="fc-experience-feed"]:not(".fc-initialized")',
  };

  const init = () => {
    $(selectors.slider).each((index, el) => {
      el.classList.add("fc-initialized");

      new Swiper(el, {
        navigation: {
          nextEl: $(el).parent().find(".swiper-button-next")[0],
          prevEl: $(el).parent().find(".swiper-button-prev")[0],
        },
        slidesPerView: 1,
        loop: false,
        // autoHeight: true,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    });
  };

  $(window).on("ess_article_loaded", init);

  return { init };
};

ExperienceFeed().init();
