import LiveChannel from "../utils/live-channel";

/**
 * Events Hero Live video
 */
const VideoHero = () => {
  const selectors = {
    player: '.fc-event-live-hero__video:not(".fc-initialized")',
  };

  const init = () => {
    $(selectors.player).each((index, el) => {
      const channelId = $(el).data("channel");
      const videoId = $(el).data("video");
      if (!channelId || !videoId) {
        return;
      }

      el.classList.add("fc-initialized");

      new LiveChannel(videoId, channelId);
    });
  };

  $(window).on("ess_article_loaded", init);

  return { init };
};

VideoHero().init();
