/**
 * Navigation
 */
const Navigation = () => {
  const init = () => {
    // Move buttons out of the menu.
    $(".fc-navigation__hamburger").on("click", toggle);
    $(".fc-navigation__menu .button.menu-item a")
      .clone()
      .addClass("button")
      .appendTo(".fc-navigation__cta-container");
    $(".fc-navigation__menu .button").remove();
  };

  const toggle = (e) => {
    $(".fc-navigation__menu").slideToggle();
  };

  return { init };
};

Navigation().init();
