/**
 * Events Vod
 */
const EventsVod = () => {
  const selectors = {
    slider: '[data-js="fc-vod-slider"]:not(".fc-initialized")',
    slide: ".swiper-slide",
    player: ".fc-event-live-hero__video",
  };

  const init = () => {
    $(selectors.slider).each((index, el) => {
      el.classList.add("fc-initialized");

      const $el = $(el);

      new Swiper(el, {
        navigation: {
          nextEl: $el.parent().find(".swiper-button-next")[0],
          prevEl: $el.parent().find(".swiper-button-prev")[0],
        },
        slidesPerView: 1,
        spaceBetween: 24,
        loop: false,
        breakpoints: {
          600: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
        },
      });

      const playerId = $el
        .closest("article")
        .find(selectors.player)
        .data("video");

      $el.find(selectors.slide).on("click", function () {
        const playerInstance = window["playerInstance_" + playerId] || "";

        if (playerInstance) {
          const newPlayerId = $(this).data("video");
          playerInstance.load(
            `https://cdn.jwplayer.com/v2/media/${newPlayerId}`
          );
          playerInstance.play();

          // Stop the existing autoplay/pip
          if (
            window.essence_video_currently_playing &&
            window.essence_video_currently_playing !== playerId
          ) {
            const oldPlayer =
              window[
                "playerInstance_" + window.essence_video_currently_playing
              ];
            oldPlayer.pause();
            // Force to disable floating.
            oldPlayer.setFloating(false);
          }

          // Scroll to top.
          const targetOffset =
            $(".fc-event-live-hero__video").offset().top -
            $("#masthead").height();
          $("html, body").animate({ scrollTop: targetOffset }, 1000);

          // Mark as playing.
          $el.find(".playing").removeClass("playing");
          $(this).addClass("playing");
        }
      });
    });
  };

  $(window).on("ess_article_loaded", init);

  return { init };
};

EventsVod().init();
